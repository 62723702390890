<template>
  <checkout-step
    title="ecommerce.checkout.address.stepTitle"
    :complete="stepData.isComplete"
    @checkout-step:request-edit="stepData.isComplete = false">
    <template #default>
      <h6 class="checkout-step-subtitle">
        {{ translateUcFirst("ecommerce.checkout.address.shippingMethodTitle") }}
      </h6>
      <large-options-input
        v-model="orderData.shippingMethodType"
        :show-price="true"
        :option-component="'LargeOptionAddOn'"
        :list="shippingMethodFieldOptions"></large-options-input>

      <div class="address-select margin-xl-top">
        <h6 class="checkout-step-subtitle">
          {{ translateUcFirst("ecommerce.checkout.address.addressTitle") }}
        </h6>
        <div class="flex gap-m flex-bottom width-expand">
          <form-input
            v-model="orderData.addressId"
            type="select2"
            icon="map-pin-location"
            :error-message="addressSelectErrorMessage"
            error-display-position="absolute"
            class="width-expand"
            :list="addressListForSelectInput"
            :translate-list="false"
            :label="false"
            placeholder="ecommerce.checkout.address.selectAddressInputPlaceholder" />
          <form-button
            style="min-width: 150px"
            icon="check"
            theme="lead"
            text="ecommerce.checkout.address.confirmAddressCta"
            @click="attemptToCompleteStep"></form-button>
          <form-button
            style="min-width: 150px"
            icon="plus"
            theme="alt"
            text="ecommerce.checkout.address.addAddressCta"
            @click="isCreatingAddress = true"></form-button>
        </div>
      </div>
      <create-user-address-modal
        :opened="isCreatingAddress"
        @modal:closed="isCreatingAddress = false"
        @create-address:success="addressCreatedHandler"></create-user-address-modal>
    </template>
    <template #complete>
      <span class="text-l">
        <span class="text-s"
          >{{ translateUcFirst(currentShippingMethod.label) }} ({{
            getShippingMethodPriceString(currentShippingMethod)
          }})
        </span>
        <br />
        <span v-if="currentShippingMethod.requiresAddress" class="text-s text-light">{{
          selectedAddressString
        }}</span>
      </span>
    </template>
  </checkout-step>
</template>

<script>
import { computed } from "vue";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import useCarts from "@/client/extensions/composition/useCarts.js";
import CheckoutStep from "@/client/components/ecommerce/checkout/CheckoutStep";
// we need to:
// 1. assign orderData.shippingMethodType - text
// 2. assign orderData.addressId - text

export default {
  components: {
    CheckoutStep,
  },
  props: {
    stepData: {
      type: Object,
      default: () => {},
    },
    orderData: {
      type: Object,
      default: () => {},
    },
    cart: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations();
    let carts = useCarts(props);
    return { asyncOps, asyncOpsReady, asyncStatus, carts };
  },
  data: function () {
    return {
      shippingMethods: [],
      addresses: [],
      isCreatingAddress: false,
      asyncData: {
        shippingMethods: {
          target: computed(() => `ecommerce/shipping/methods/cart/${this.cart.uuid}`),
          data: {},
          options: {
            dataMutator: (data, res) => {
              return data;
            },
          },
        },
        addresses: {
          target: computed(() => `ecommerce/address/`),
          data: {
            userId: this.$store.getters["user/profile"].id,
          },
          options: {
            dataMutator: (data, res) => {
              if (data.items) {
                return data.items;
              }

              return [];
            },
          },
        },
      },
      shippingMethodType: false,
      addressSelectErrorMessage: "",
    };
  },
  computed: {
    addressListForSelectInput() {
      let res = [];
      if (!this.addresses || !Array.isArray(this.addresses)) {
        return [];
      }
      this.addresses.forEach((address) => {
        res.push({
          value: address.id,
          label: address.addressString,
        });
      });

      return res;
    },
    shippingMethodFieldOptions() {
      if (!this.shippingMethods || !Array.isArray(this.shippingMethods)) {
        return [];
      }

      let res = this.shippingMethods.map((item) => ({
        value: item.type,
        label: item.label,
        title: item.label,
        text: item.description,
        price: item.price,
        currency: this.cart?.quote?.currency ? this?.cart?.quote?.currency : undefined,
        // selected: item.price === 0,
      }));

      return res;
    },
    currentShippingMethod() {
      if (!this.shippingMethods) {
        return false;
      }

      for (const method of this.shippingMethods) {
        if (method.type === this.orderData.shippingMethodType) {
          return method;
        }
      }
      return false;
    },
    canComplete() {
      console.log("compute can complete");
      if (!this.currentShippingMethod) {
        return false;
      }

      if (this.currentShippingMethod.requiresAddress && !this.orderData.addressId) {
        return false;
      }

      return true;
    },
    selectedAddressString() {
      let addressId = this.orderData.addressId;
      if (!addressId) {
        return "";
      }

      for (const address of this.addresses) {
        if (address.id === addressId) {
          return address.addressString;
        }
      }

      return "";
    },
  },
  watch: {
    currentShippingMethod(newVal) {
      if (!this.currentShippingMethod.requiresAddress) {
        this.addressSelectErrorMessage = "";
      }
    },
    orderData: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.addressSelectErrorMessage = "";
        }
      },
    },
  },
  methods: {
    async addressCreatedHandler(data) {
      this.isCreatingAddress = false;
      await this.refreshAsyncData();
      this.orderData.addressId = data.result.data.item.id;
    },
    attemptToCompleteStep(arg) {
      if (this.canComplete) {
        this.stepData.isComplete = true;
      } else {
        this.addressSelectErrorMessage = this.translate(
          "ecommerce.checkout.errorMessages.selectAnAddressBeforeConfirmAddress"
        );
      }
    },
    getShippingMethodPriceString(item) {
      if (item.price && item.price != 0) {
        return this.translateNumber(item.price, "currency", {
          currency: this.cart.quote.currency,
        });
      } else {
        return this.translate("ecommerce.general.free");
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
